import React, { useState } from "react"
import { faWallet } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loader from "../../common/utils/loader"
import Button from "../../common/utils/actionButton"
import NetworkSelection from "../networkSelection"
import { FloatingInput } from "../../common/utils/inputs"
import abis from "../../../blockchain/abis"

const TokenSearch = ({
  setShowModal,
  network,
  setNetwork,
  testnet,
  setTestnet,
  account,
  setAddress,
  strings,
  title,
  library,
  onSuccess,
}) => {
  const [potentialAddress, setPotentialAddress] = useState("")
  const [pending, setPending] = useState()
  const formCompleted = () => {
    return account && potentialAddress.match(/^0x[a-f0-9]{40}$/gi)
  }
  const wallet = (
    <span>
      <FontAwesomeIcon icon={faWallet} />{" "}
      {account ? account.slice(0, 6) + "..." : "Not connected"}
    </span>
  )

  let actionButton = (
    <Button callback={() => setShowModal(true)}>Connect wallet</Button>
  )

  if (account)
    actionButton = (
      <div className="flex w-full flex-row items-center justify-between ">
        {wallet}
        <Button
          disabled={!formCompleted()}
          callback={async () => {
            setPending(true)
            try {
              const contract = new library.eth.Contract(
                abis.basic,
                potentialAddress
              )
              const owner = await contract.methods.owner().call()
              if (owner === account) onSuccess()
              console.log(owner, account)
            } catch (error) {
              console.error(error)
            }
            setPending(false)
          }}
        >
          Search token
        </Button>
      </div>
    )
  if (pending)
    actionButton = (
      <div className="flex w-full flex-row items-center justify-between ">
        {wallet}
        <Button disabled={true}>
          {/** TODO: Replace for an actual string from CMS */}
          <div className="flex flex-nowrap items-center gap-2">
            <div className="w-6">
              <Loader color="text-primary" />
            </div>
            Pending
          </div>
        </Button>
      </div>
    )
  return (
    <section className="flex w-full flex-col items-center justify-center gap-8 bg-white p-8">
      <h1 className="text-3xl font-bold">{title}</h1>
      <h2 className="text-left text-xl">
        {strings?.configurator?.subtitle || "Let's find your token first!"}
      </h2>
      {/** TODO: Select text from the CMS with CONFIGURATION tips */}
      <NetworkSelection
        networkTip={strings.networkOptions.network.tip}
        toggleTip={strings.networkOptions.network.testnet.tip}
        toggleLabel={strings.networkOptions.network.testnet.label}
        selected={network}
        setNetwork={value => setNetwork(parseInt(value))}
        setTestnet={setTestnet}
        placeholder={strings.networkOptions.network.placeholder}
        testnet={testnet}
      />
      {/** TODO: Select text from the CMS */}
      <FloatingInput
        tip={
          strings?.information?.tip ||
          "Tell us the address of the token you deployed."
        }
        type="text"
        name="address"
        placeholder={strings?.information?.placeholder || "Token Address"}
        setValue={value => {
          setAddress(value)
          setPotentialAddress(value)
        }}
      />
      <div className="flex w-full justify-end">{actionButton}</div>
    </section>
  )
}

export default TokenSearch
