import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import abis from "../../../blockchain/abis"
import Button from "../../common/utils/actionButton"
import ConfigurationOption from "../../common/utils/configurationOption"
import { FloatingInput, Toggle } from "../../common/utils/inputs"
import ContentWithTooltip from "../../common/utils/tooltip"

const ConfigurationForm = ({ address, provider, account }) => {
  const [frozen, setFrozen] = useState()
  const title = "Token configuration"
  const subtitle = "Available options"
  const [token, setToken] = useState()
  const [tokenName, setTokenName] = useState()
  const [tokenSymbol, setTokenSymbol] = useState()
  const [tokenSupply, setTokenSupply] = useState()
  const [tokenDecimals, setTokenDecimals] = useState()
  const [buyTax, setBuyTax] = useState()
  const [newOwner, setNewOwner] = useState()
  const [sellTax, setSellTax] = useState()
  const [supplyChange, setSupplychange] = useState(0)
  const [banList, setBanList] = useState()
  const [willBan, setWillBan] = useState(true)
  const [whiteList, setWhiteList] = useState()
  const [willWhiteList, setWillWhiteList] = useState()
  const [toBurn, setToBurn] = useState()
  const [mint, setMint] = useState({})
  const [mintable, setMintable] = useState()
  const [freezable, setFreezable] = useState()
  const [taxable, setTaxable] = useState()
  const [burnable, setBurnable] = useState()
  const [bannable, setBannable] = useState()

  useEffect(() => {
    if (!provider) return
    const contract = new provider.Contract(abis.basic, address)
    setToken(contract)
    const methods = [
      { name: "name", callback: setTokenName },
      { name: "totalSupply", callback: setTokenSupply },
      { name: "symbol", callback: setTokenSymbol },
      { name: "decimals", callback: setTokenDecimals },
      { name: "frozen", callback: setFrozen },
      { name: "mintable", callback: setMintable },
      { name: "freezable", callback: setFreezable },
      { name: "taxable", callback: setTaxable },
      { name: "burnable", callback: setBurnable },
      { name: "bannable", callback: setBannable },
      { name: "buyTax", callback: tax => setBuyTax(tax / 1000) },
      { name: "sellTax", callback: tax => setSellTax(tax / 1000) },
    ]
    methods.forEach(method =>
      contract.methods[method.name]()
        .call()
        .then(value => method.callback(value))
    )
  }, [])

  useEffect(() => {
    if (!token) return
    token.methods
      .totalSupply()
      .call()
      .then(totalSupply => setTokenSupply(totalSupply))
  }, [supplyChange])

  return (
    <section className="flex w-full flex-col items-center justify-center gap-6 bg-white p-8">
      <h1 className="text-3xl font-bold">{title}</h1>
      <div className="flex w-full flex-col items-start gap-2">
        <div>
          You are configuring the <span className="font-bold">{tokenName}</span>{" "}
          token
        </div>
        <div>
          Address:{" "}
          <span className="font-bold">
            {address?.slice(0, 8)} . . . {address.slice(-6)}
          </span>
        </div>
        <div>
          Total supply:{" "}
          <span className="font-bold">
            {tokenSupply?.slice(0, -tokenDecimals)} {tokenSymbol}
          </span>
        </div>
      </div>
      {/** Token network options */}
      <section className="flex w-full flex-col gap-4">
        <h2 className="w-full text-start text-xl">{subtitle}</h2>
        <div className="flex w-full flex-col items-center gap-4">
          {/** Mint */}
          {mintable && (
            <ConfigurationOption
              name="Mint"
              callback={() => {
                token.methods
                  .mint(mint.amount, mint.destination?.trim() || account)
                  .send({ from: account })
                  .then(() => setSupplychange(supplyChange + 1))
              }}
            >
              <div className="grid w-full grid-cols-2 gap-3">
                <FloatingInput
                  tip={`How many ${tokenSymbol} will you mint? (no decimals)`}
                  type="number"
                  name="to-mint"
                  placeholder="Amount"
                  setValue={value =>
                    setMint({
                      ...mint,
                      amount: value + "0".repeat(tokenDecimals),
                    })
                  }
                />
                <FloatingInput
                  tip={`Where will the coins go? (leave empty if they go to your wallet)`}
                  type="text"
                  name="mint-destination"
                  placeholder="Destination"
                  setValue={value =>
                    setMint({
                      ...mint,
                      destination: value,
                    })
                  }
                />
              </div>
            </ConfigurationOption>
          )}
          {/** Burn */}
          {burnable && (
            <ConfigurationOption
              name="Burn tokens"
              callback={() => {
                token.methods
                  .transfer(
                    "0x" + "0".repeat(40),
                    toBurn + "0".repeat(tokenDecimals)
                  )
                  .send({ from: account })
                  .then(() => setSupplychange(supplyChange + 1))
              }}
            >
              <FloatingInput
                tip="Enter the amount of tokens to burn"
                type="text"
                name="to-burn"
                placeholder="Tokens to burn"
                setValue={value => setToBurn(value)}
              />
            </ConfigurationOption>
          )}
          {/** Ban */}
          {bannable && (
            <ConfigurationOption
              name="Modify ban list"
              callback={() => {
                token.methods
                  .updateInternalLists(
                    1,
                    banList,
                    banList.map(() => willBan)
                  )
                  .send({ from: account })
              }}
            >
              <div className="grid w-full grid-cols-3 items-center gap-2">
                <div className="col-span-2">
                  <FloatingInput
                    tip="Enter addresses, separated with a comma"
                    type="text"
                    name="to-ban-list"
                    placeholder={`Addresses to ${willBan ? "ban" : "unban"}`}
                    setValue={value =>
                      setBanList(value.replace(/ /g, "").split(","))
                    }
                  />
                </div>
                <Toggle
                  tip={`Check if you are BANNING wallets. Uncheck otherwise.`}
                  name="ban"
                  value={willBan}
                  handleChange={() => setWillBan(!willBan)}
                  label="Banned"
                />
              </div>
            </ConfigurationOption>
          )}
          {/** Add to whitelist */}
          {taxable && (
            <ConfigurationOption
              name="Modify whitelist"
              callback={() => {
                token.methods
                  .updateInternalLists(
                    2,
                    whiteList,
                    whiteList.map(() => willWhiteList)
                  )
                  .send({ from: account })
              }}
            >
              <div className="grid w-full grid-cols-3 items-center gap-2">
                <div className="col-span-2">
                  <FloatingInput
                    tip="Enter addresses, separated with a comma"
                    type="text"
                    name="to-ban-list"
                    placeholder={`Addresses to ${
                      willWhiteList ? "add" : "remove"
                    }`}
                    setValue={value =>
                      setWhiteList(value.replace(/ /g, "").split(","))
                    }
                  />
                </div>
                <Toggle
                  tip={`Check if you are ADDING wallets to the whitelist. Uncheck otherwise.`}
                  name="whitelist"
                  value={willWhiteList}
                  handleChange={() => setWillWhiteList(!willWhiteList)}
                  label="Add"
                />
              </div>
            </ConfigurationOption>
          )}
          {/** Modify taxes */}
          {taxable && (
            <ConfigurationOption
              name="Modify taxes"
              callback={() => {
                token.methods
                  .setTax(buyTax * 1000, sellTax * 1000)
                  .send({ from: account })
              }}
            >
              <div className="grid w-full grid-cols-2 items-center gap-2">
                <FloatingInput
                  tip="The new buy tax"
                  type="number"
                  name="buy-tax"
                  value={buyTax}
                  placeholder={`New buy tax (%)`}
                  setValue={value => setBuyTax(value)}
                />
                <FloatingInput
                  tip="The new sell tax"
                  type="number"
                  name="sell-tax"
                  value={sellTax}
                  placeholder={`New sell tax (%)`}
                  setValue={value => setSellTax(value)}
                />
              </div>
            </ConfigurationOption>
          )}
          {/** Transfer ownership */}
          <ConfigurationOption
            name="Transfer ownership"
            callback={() => {
              token.methods.transferOwnership(newOwner).send({ from: account })
            }}
          >
            <div className="flex w-full items-center justify-center">
              <FloatingInput
                tip={`The new owner address for the ${tokenSymbol} token`}
                type="text"
                name="new-owner"
                placeholder={`New owner address`}
                setValue={value => setNewOwner(value)}
              />
            </div>
          </ConfigurationOption>
          {/** Mint and renounce */}
          <div className="flex w-full transform-gpu justify-between gap-2 duration-500 md:w-2/3">
            {freezable && (
              <ContentWithTooltip tip="Freezing the token means transactions will no longer work. Unfreezing reverts this behaviour.">
                <Button
                  callback={() =>
                    token.methods
                      .setFrozen(!frozen)
                      .send({ from: account })
                      .then(() => setFrozen(!frozen))
                  }
                >
                  {frozen ? "Unfreeze" : "Freeze"}
                </Button>
              </ContentWithTooltip>
            )}
            <ContentWithTooltip tip="Renouncing to the ownership of a token means you will never be able to manage it again. This action is IRREVERSIBLE.">
              <Button
                background="bg-red-500"
                callback={() =>
                  token.methods
                    .transferOwnership("0x" + "0".repeat(40))
                    .send({ from: account })
                    .then(() => globalThis?.location?.reload())
                }
              >
                Renounce ownership
              </Button>
            </ContentWithTooltip>
          </div>
        </div>
      </section>
    </section>
  )
}

export default ConfigurationForm
