import React, { useState } from "react"
import Button, { SelectionButton } from "./actionButton"

const ConfigurationOption = ({ callback, name, children }) => {
  const [willDo, setWillDo] = useState()
  return (
    <>
      <SelectionButton
        callback={() => setWillDo(!willDo)}
        width="w-full md:w-2/3"
        title={name}
      />
      <div
        className={`${
          willDo ? "flex" : "hidden"
        } flex w-full flex-col items-end gap-2`}
      >
        {children}
        <Button callback={callback}>Confirm</Button>
      </div>
    </>
  )
}

export default ConfigurationOption
