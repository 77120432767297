import { useState } from "react"

const useTokenForm = () => {
  const [name, setName] = useState()
  const [supply, setSupply] = useState()
  const [symbol, setSymbol] = useState()
  const [decimals, setDecimals] = useState(18)
  const [mintable, setMintable] = useState(false)
  const [burnable, setBurnable] = useState(false)
  const [bannable, setBannable] = useState(false)
  const [freezable, setFreezable] = useState(false)
  const [frozen, setFrozen] = useState(false)
  const [taxable, setTaxable] = useState(false)
  const [buyTax, setBuyTax] = useState(0)
  const [sellTax, setSellTax] = useState(0)
  const [router, setRouter] = useState(
    "0x0000000000000000000000000000000000000000"
  )
  const [network, setNetwork] = useState()
  return {
    name,
    setName,
    supply,
    setSupply,
    symbol,
    setSymbol,
    decimals,
    setDecimals,
    mintable,
    setMintable,
    burnable,
    setBurnable,
    freezable,
    setFreezable,
    frozen,
    setFrozen,
    taxable,
    setTaxable,
    network,
    setNetwork,
    bannable,
    setBannable,
    buyTax,
    setBuyTax,
    sellTax,
    setSellTax,
    router,
    setRouter,
  }
}

export default useTokenForm
