import React, { useState, useEffect } from "react"
import Button from "../../common/utils/actionButton"
import { FloatingInput } from "../../common/utils/inputs"
import NetworkSelection from "../networkSelection"
import { faWallet } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loader from "../../common/utils/loader"
import { changeNetwork } from "../../../common/insist"
import { networkData } from "../../../common/networks"
import TokenSearch from "./search"
import ConfigurationForm from "./configure"

const Configurator = ({
  title,
  strings,
  chainId,
  library,
  active,
  setShowModal,
  account,
}) => {
  const [testnet, setTestnet] = useState(false)
  const [network, setNetwork] = useState()
  const [address, setAddress] = useState()
  const [found, setFound] = useState()
  useEffect(() => {
    if (!network && !active) return
    if (chainId && !network) setNetwork(chainId)
    else if (network && !active) {
      setShowModal(true)
    } else if (network !== chainId) {
      const newChainId =
        networkData[testnet ? "testnet" : "mainnet"][network]?.wallet
      if (newChainId)
        changeNetwork(
          library.currentProvider,
          networkData[testnet ? "testnet" : "mainnet"][network]?.wallet
        )
    }
  }, [network, chainId, active, library])

  return (
    <div className="flex h-max w-full flex-row-reverse bg-white">
      {/** These components are in reverse order */}
      {/** Token configuration */}
      <div className={`${found ? "" : "h-[50vh]"} w-full`}>
        {found ? (
          <ConfigurationForm
            provider={library.eth}
            account={account}
            address={address}
          />
        ) : (
          <TokenSearch
            network={network}
            setNetwork={setNetwork}
            testnet={testnet}
            setTestnet={setTestnet}
            account={account}
            setAddress={setAddress}
            library={library}
            strings={strings}
            title={title}
            onSuccess={() => setFound(!found)}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </div>
  )
}

export default Configurator
