import React, { useState } from "react"

const ContentWithTooltip = ({ children, tip }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <div
      className="group relative w-full"
      onMouseLeave={() => setShowTooltip(false)}
      onMouseEnter={() => setShowTooltip(true)}
      onFocus={() => setShowTooltip(true)}
      onBlur={() => setShowTooltip(false)}
    >
      {children}
      {tip && showTooltip && (
        <section className="absolute bottom-full left-0 z-50 w-full -translate-y-1">
          <div className=" flex w-full flex-col items-center justify-center">
            <div className="z-50 w-max max-w-full translate-y-3 rounded-lg bg-slate-700 p-2 text-sm text-white">
              {tip}
            </div>
            <div className="z-0 h-4 w-4 rotate-45 rounded-sm bg-slate-700"></div>
          </div>
        </section>
      )}
    </div>
  )
}

export default ContentWithTooltip
