import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../../web3/connector"
import { deploy, addToken } from "../../../common/deployer"
import Layout from "../../../components/common/body/layout"
import WalletSelectionModal from "../../../components/common/utils/walletSelection"
import Form from "../../../components/eva/form"
import Modal from "../../../components/common/utils/modal"
import Card from "../../../components/common/utils/card"
import { HeadHelmet } from "../../../components/common/body/seo"
import Configurator from "../../../components/eva/configurator"

const Eva = ({ data: { globals, solution } }) => {
  const { active, account, chainId, library, activate } = useWeb3React()
  const [selectedOption, setSelectedOption] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const formRef = useRef()

  async function connect() {
    try {
      await activate(injected)
    } catch (err) {
      console.log(err)
    }
  }

  // Hide wallet selection modal after connection
  useEffect(() => {
    if (active) setShowModal(false)
  }, [active])

  useEffect(() => {
    formRef.current.classList.remove("scale-0")
    formRef.current.classList.add("scale-100")
  }, [])
  // TODO: Get text from CMS

  return (
    <Layout
      seo={{
        description: globals.description,
      }}
      lang={globals.node_locale}
      title={`${globals.title} - ${solution.name}`}
      site={globals.title}
      url={`/solutions/${solution.slug}`}
      preview={solution.twitterImage.localFile.publicURL}
      solutions={globals.solutionList}
      contactLink={globals.contactLink}
      strings={globals.strings}
      solutionsHeader={globals.solutionsHeader}
      showModal={showModal}
    >
      <div className="w-full snap-start snap-normal bg-white bg-creation bg-cover bg-fixed bg-center">
        <div className="flex h-full w-full flex-col items-center justify-center gap-8 bg-white bg-opacity-20 p-8 text-center ">
          <div className="mx-auto max-w-2xl">
            <Card background="card-background">
              <div className="flex w-full flex-col gap-2 p-4">
                <p>
                  Welcome to Eva Delpoyer, a tool to easily deploy smart
                  contracts on the blockchain!
                </p>
                <p>
                  Want to make a token for your future idea, for a launchpad or
                  anything else?
                </p>
                <p>
                  Thanks to Eva, it is now possible with no coding requirements!
                </p>
              </div>
            </Card>
          </div>
          <div
            className="mx-auto grid w-full max-w-lg scale-0 transform-gpu grid-cols-1 duration-500"
            ref={formRef}
          >
            <div className="grid grid-cols-2">
              {/** TODO: Use text from CMS */}
              <Card
                pointy={{ bl: true, br: true, tr: true }}
                hiddenBorders={{ b: true, r: true }}
                hiddenShadow
                onClick={() => setSelectedOption(1)}
                background={`transform-gpu duration-500 ${
                  selectedOption === 1
                    ? "bg-white font-bold text-lg"
                    : "bg-slate-100 text-slate-500 text-sm flex items-center justify-center"
                }`}
              >
                <div className="p-4">Create</div>
              </Card>
              <Card
                pointy={{ bl: true, br: true, tl: true }}
                hiddenBorders={{ b: true }}
                onClick={() => setSelectedOption(2)}
                background={`transform-gpu duration-500 ${
                  selectedOption === 2
                    ? "bg-white font-bold text-lg"
                    : "bg-slate-100 text-slate-500 text-sm flex items-center justify-center"
                }`}
              >
                <div className="p-4">Configure</div>
              </Card>
            </div>
            <div>
              {/** TODO: Use text from CMS */}
              <Card pointy={{ tl: true, tr: true }}>
                {selectedOption === 1 ? (
                  <Form
                    addToken={addToken}
                    active={active}
                    account={account}
                    library={library}
                    setShowModal={setShowModal}
                    deploy={deploy}
                    chainId={chainId}
                    strings={solution.strings}
                    title={solution.name}
                  />
                ) : (
                  <Configurator
                    title={"Eva configurator"}
                    strings={solution.strings}
                    chainId={chainId}
                    library={library}
                    active={active}
                    setShowModal={setShowModal}
                    account={account}
                  />
                )}
              </Card>
            </div>
          </div>
          {/** TODO: replace text for `strings` attributes */}
          <Modal
            title={solution.strings.connection.title}
            shown={showModal}
            close={globals.strings.close}
            onClose={() => setShowModal(false)}
          >
            <WalletSelectionModal
              strings={solution.strings.connection}
              connect={async provider => {
                await connect(provider)
                setShowModal(false)
              }}
            />
          </Modal>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ location, data: { globals, solution } }) => {
  return (
    <HeadHelmet
      seo={{
        description: solution.excerpt,
      }}
      lang={globals.node_locale}
      title={`${globals.title} - ${solution.name}`}
      site={globals.title}
      location={location.pathname}
      preview={solution.twitterImage.localFile.publicURL}
    />
  )
}

export default Eva

export const query = graphql`
  {
    globals: contentfulPage {
      title
      description
      solutionsHeader
      solutionList {
        name
        slug
        released
        available
        shown
        node_locale
      }
      contactLink {
        label
        link
      }
      strings {
        goHome
        close
        copyright
        openMenu
        about {
          link
          label
        }
      }
      node_locale
    }
    solution: contentfulSolutions(
      id: { eq: "5e141f7a-4304-594c-8c1a-a3f5eafbf716" }
    ) {
      name
      slug
      excerpt
      strings {
        id
        connection {
          description
          popular
          title
        }
        creation {
          action
          confirmation
        }
        customization {
          taxes {
            error
            placeholder
            tip
          }
          title
          toggles {
            label
            tip
          }
        }
        errors {
          creation
          required
        }
        information {
          fields {
            error
            placeholder
            tip
          }
          title
        }
        networkOptions {
          exchange {
            placeholder
            tip
          }
          network {
            placeholder
            tip
            testnet {
              label
              tip
            }
          }
          title
        }
        terms {
          label
          link
        }
      }
      twitterImage {
        localFile {
          publicURL
        }
      }
    }
  }
`
