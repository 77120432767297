import React, { useEffect } from "react"
import Card from "./card"

const Modal = ({ children, title, footer, close, shown, onClose }) => {
  return (
    <div
      aria-hidden={!shown}
      className={`${
        shown ? "z-50" : "-z-50 delay-300"
      } fixed inset-0 flex h-screen w-full items-center justify-center overflow-hidden bg-gray-700/50 backdrop-blur-md transition-all duration-0`}
    >
      <div
        className={`w-full max-w-2xl transform-gpu p-4 duration-300 ${
          shown ? "delay-200" : "opacity-0"
        }`}
      >
        {/** Modal content */}
        <Card>
          <div className="relative bg-white">
            {/** Modal header */}
            <div className="flex items-start justify-between rounded-t border-b p-4">
              <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
              <button
                type="button"
                aria-label={close}
                className="ml-auto inline-flex transform-gpu items-center rounded-lg border border-transparent bg-transparent p-1.5 text-sm text-primary outline-none duration-200 hover:border-primary focus-visible:border-primary"
                onClick={onClose}
              >
                <svg
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">{close}</span>
              </button>
            </div>
            {/** Modal body */}
            <div className="space-y-6 p-6">{children}</div>
            {/** Modal footer */}
            {footer && (
              <div className="w-full space-x-2 rounded-b border-t border-gray-200 p-6">
                {footer}
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Modal
