import React from "react"
import { networkData } from "../../common/networks"
import { GraphicalSelection } from "../common/utils/selects"
import { Toggle } from "../common/utils/inputs"
const NetworkSelection = ({
  networkTip,
  toggleTip,
  toggleLabel,
  setNetwork,
  testnet,
  setTestnet,
  selected,
  placeholder,
}) => {
  const networkOptions = Object.keys(
    networkData[testnet ? "testnet" : "mainnet"]
  ).map(networkId => ({
    label: networkData[testnet ? "testnet" : "mainnet"][
      networkId
    ].wallet.chainName.replace(/ (test|main)net/gi, ""),
    value: parseInt(networkId),
  }))
  return (
    <div className="flex w-full flex-row gap-2">
      <div className="flex w-1/3 items-center">
        <Toggle
          tip={toggleTip}
          name="testnet"
          value={testnet}
          handleChange={() => setTestnet(!testnet)}
          label={toggleLabel}
        />
      </div>
      <div className="w-2/3">
        <GraphicalSelection
          tip={networkTip}
          getIcon={network =>
            networkData[testnet ? "testnet" : "mainnet"][network]?.wallet
              ?.nativeCurrency?.image
          }
          setValue={setNetwork}
          selected={selected}
          placeholder={placeholder}
          options={networkOptions}
          name="network"
        />
      </div>
    </div>
  )
}

export default NetworkSelection
