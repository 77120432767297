import React, { useEffect, useState } from "react"
import ContentWithTooltip from "./tooltip"
export const GraphicalSelection = ({
  tip,
  getIcon,
  options,
  setValue,
  placeholder,
  name,
  selected,
}) => {
  const [icon, setIcon] = useState()
  const value = ~options.map(option => option.value).indexOf(selected)
    ? selected
    : undefined
  useEffect(() => {
    setIcon(getIcon(selected))
  }, [selected, getIcon])
  return (
    <ContentWithTooltip tip={tip}>
      <div class="flex w-full h-12">
        {icon && (
          <div class="w-14 flex-shrink-0 z-10 flex justify-center items-center py-2 px-3 text-sm font-medium text-center text-gray-500 border border-gray-300 rounded-l-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-100">
            <img src={icon} alt="Network icon" className="w-full" />
          </div>
        )}
        <label for={name} class="sr-only">
          {placeholder}
        </label>
        <select
          onChange={e => {
            setValue(e.target.value)
            setIcon(getIcon(e.target.value))
          }}
          id={name}
          value={value}
          class={`border border-gray-300 text-gray-500 text-sm rounded-r-lg ${
            icon ? "border-l-0" : "border-l rounded-lg"
          } focus:border-sky focus:ring-0 block w-full px-3 pl-3 pr-8`}
        >
          <option>{placeholder}</option>
          {options.map(option => (
            <option
              value={option.value}
              key={`option-${option.label}-${option.value}`}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </ContentWithTooltip>
  )
}
