import React, { useState, useEffect, useRef } from "react"
import {
  faWallet,
  faFileCircleCheck,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useTokenForm from "../../hooks/useTokenForm"
import { changeNetwork } from "../../common/insist"
import Button from "../../components/common/utils/actionButton"
import { GraphicalSelection } from "../../components/common/utils/selects"
import { FloatingInput, Toggle } from "../../components/common/utils/inputs"
import Loader from "../common/utils/loader"
import NetworkSelection from "./networkSelection"
import { networkData } from "../../common/networks"

const Form = ({
  addToken,
  active,
  account,
  chainId,
  library,
  setShowModal,
  deploy,
  strings,
  title,
}) => {
  const [exchangeOptions, setExchangeOptions] = useState([])
  const [networkOptions, setNetworkOptions] = useState([])
  const [deployedContract, setDeployedContract] = useState()
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [testnet, setTestnet] = useState(false)
  const [pending, setPending] = useState(false)
  const form = useTokenForm()

  const formCompleted = () => {
    // Check if the form has been completed
    if (!form.network) return false
    if (!form.name) return false
    if (!form.supply) return false
    if (isNaN(form.supply)) return false
    if (form.supply < 0) return false
    if (!form.symbol) return false
    if (!form.decimals) return false
    if (isNaN(form.decimals)) return false
    if (form.decimals < 0) return false
    if (form.taxable && !parseInt(form.router)) return false
    if (
      form.taxable &&
      !(
        (form.buyTax > 0 && form.buyTax <= 100) ||
        (form.sellTax > 0 && form.sellTax <= 100)
      )
    )
      return false
    // Check if the terms have been accepted
    if (!acceptedTerms) return false
    // Check if the numbers do not overflow
    return (
      BigInt(form.supply + "0".repeat(form.decimals)) <
      115792089237316195423570985008687907853269984665640564039457584007913129639935n
    )
  }

  // Set avaliable networks
  useEffect(() => {
    const exchangeOptions = Object.keys(
      networkData[testnet ? "testnet" : "mainnet"][form.network]?.exchanges ||
        {}
    ).map(address => ({
      label:
        networkData[testnet ? "testnet" : "mainnet"][form.network]?.exchanges[
          address
        ].name,
      value: address,
    }))
    setExchangeOptions(exchangeOptions)
  }, [form.network, testnet])

  // Select network
  useEffect(() => {
    if (!form.network && !active) return
    if (chainId && !form.network) form.setNetwork(chainId)
    else if (form.network && !active) {
      setShowModal(true)
    } else if (form.network !== chainId) {
      const newChainId =
        networkData[testnet ? "testnet" : "mainnet"][form.network]?.wallet
      if (newChainId)
        changeNetwork(
          library.currentProvider,
          networkData[testnet ? "testnet" : "mainnet"][form.network]?.wallet
        )
    }
  }, [form.network, chainId, active, library])

  // Refresh the exchanges after network selection
  useEffect(() => {
    const networkOptions = Object.keys(
      networkData[testnet ? "testnet" : "mainnet"]
    ).map(networkId => ({
      label: networkData[testnet ? "testnet" : "mainnet"][
        networkId
      ].wallet.chainName.replace(/ (test|main)net/gi, ""),
      value: parseInt(networkId),
    }))
    setNetworkOptions(networkOptions)
  }, [testnet])

  let actionButton = (
    <Button callback={() => setShowModal(true)}>
      {strings.connection.title}
    </Button>
  )
  const wallet = (
    <span>
      <FontAwesomeIcon icon={faWallet} /> {account?.slice(0, 6)}...
    </span>
  )
  if (active)
    actionButton = (
      <div className="flex w-full flex-row items-center justify-between ">
        {wallet}
        <Button
          disabled={!formCompleted()}
          callback={async () => {
            setPending(true)
            try {
              const contract = await deploy(library, form)
              setDeployedContract(contract)
              addToken(library, form, contract.contractAddress)
            } catch (error) {
              console.error(error)
            }
            setPending(false)
          }}
        >
          {strings.creation.action}
        </Button>
      </div>
    )
  if (pending)
    actionButton = (
      <div className="flex w-full flex-row items-center justify-between ">
        {wallet}
        <Button disabled={true}>
          {/** TODO: Replace for an actual string from CMS */}
          <div className="flex flex-nowrap items-center gap-2">
            <div className="w-6">
              <Loader color="text-primary" />
            </div>
            Pending
          </div>
        </Button>
      </div>
    )

  return (
    <form className="flex w-full flex-col space-y-8 bg-white p-8">
      <h1 className="text-3xl font-bold">{title}</h1>
      {/** Token network options */}
      <section className="flex w-full flex-col gap-4">
        <h2 className="w-full text-start text-xl">
          {strings.networkOptions.title}
        </h2>

        <NetworkSelection
          networkTip={strings.networkOptions.network.tip}
          toggleTip={strings.networkOptions.network.testnet.tip}
          toggleLabel={strings.networkOptions.network.testnet.label}
          selected={form.network}
          setNetwork={value => form.setNetwork(parseInt(value))}
          setTestnet={setTestnet}
          placeholder={strings.networkOptions.network.placeholder}
          testnet={testnet}
        />
      </section>
      {/** Token mandatory params */}
      <section className="flex w-full flex-col space-y-4 pb-4">
        <h2 className="w-full text-start text-xl">
          {strings.information.title}
        </h2>
        <div className="grid w-full grid-cols-3 gap-2">
          <div className="col-span-2">
            <FloatingInput
              tip={strings.information.fields[0].tip}
              type="text"
              name="name"
              placeholder={strings.information.fields[0].placeholder}
              setValue={form.setName}
            />
          </div>
          <FloatingInput
            tip={strings.information.fields[1].tip}
            type="text"
            name="symbol"
            placeholder={strings.information.fields[1].placeholder}
            setValue={form.setSymbol}
          />
        </div>
        <div className="grid w-full grid-cols-2 gap-2">
          <FloatingInput
            tip={strings.information.fields[2].tip}
            type="number"
            name="supply"
            placeholder={strings.information.fields[2].placeholder}
            setValue={form.setSupply}
          />
          <FloatingInput
            tip={strings.information.fields[3].tip}
            value={form.decimals}
            type="number"
            name="decimals"
            max={70}
            placeholder={strings.information.fields[3].placeholder}
            setValue={form.setDecimals}
          />
        </div>
      </section>
      {/** Token custom options */}
      <section className="flex w-full flex-col space-y-4">
        <h2 className="w-full text-start text-xl">
          {strings.customization.title}
        </h2>
        <section className="flex flex-col gap-4">
          <div className="grid w-full grid-cols-2 gap-4">
            <Toggle
              tip={strings.customization.toggles[0].tip}
              name="mintable"
              value={form.mintable}
              handleChange={() => form.setMintable(!form.mintable)}
              label={strings.customization.toggles[0].label}
            />
            <Toggle
              tip={strings.customization.toggles[1].tip}
              name="burnable"
              value={form.burnable}
              handleChange={() => form.setBurnable(!form.burnable)}
              label={strings.customization.toggles[1].label}
            />
            <Toggle
              tip={strings.customization.toggles[2].tip}
              name="freezable"
              value={form.freezable}
              handleChange={() => form.setFreezable(!form.freezable)}
              label={strings.customization.toggles[2].label}
            />
            <Toggle
              tip={strings.customization.toggles[3].tip}
              name="bannable"
              value={form.bannable}
              handleChange={() => form.setBannable(!form.bannable)}
              label={strings.customization.toggles[3].label}
            />
            <Toggle
              tip={strings.customization.toggles[4].tip}
              name="taxable"
              value={form.taxable}
              handleChange={() => form.setTaxable(!form.taxable)}
              label={strings.customization.toggles[4].label}
            />
            <div
              className={`transform-gpu transition-opacity duration-200 ease-out ${
                form.freezable ? "opacity-100" : "-z-50 opacity-0"
              }`}
            >
              <Toggle
                tip={strings.customization.toggles[5].tip}
                name="frozen"
                value={form.frozen}
                handleChange={() => form.setFrozen(!form.frozen)}
                label={strings.customization.toggles[5].label}
              />
            </div>
          </div>
          {form.taxable && (
            <div className="grid w-full grid-cols-2 gap-4">
              <FloatingInput
                tip={strings.customization.taxes[0].tip}
                type="number"
                name="buytax"
                placeholder={strings.customization.taxes[0].placeholder}
                setValue={form.setBuyTax}
              />
              <FloatingInput
                tip={strings.customization.taxes[1].tip}
                type="number"
                name="selltax"
                placeholder={strings.customization.taxes[1].placeholder}
                setValue={form.setSellTax}
              />
            </div>
          )}
          {form.taxable && (
            <div className="w-full">
              <GraphicalSelection
                tip={strings.networkOptions.exchange.tip}
                getIcon={exchange =>
                  networkData[testnet ? "testnet" : "mainnet"][form.network]
                    ?.exchanges[exchange]?.icon
                }
                setValue={value => form.setRouter(value)}
                selected={form.router}
                placeholder={strings.networkOptions.exchange.placeholder}
                options={exchangeOptions}
                name="exchange"
              />
            </div>
          )}
        </section>
      </section>
      <section className="flex w-full flex-row items-center gap-2">
        <Toggle
          name="terms"
          value={acceptedTerms}
          handleChange={() => setAcceptedTerms(!acceptedTerms)}
          label={
            <a
              className="flex flex-row gap-2"
              href={strings.terms.link}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFileCircleCheck} /> {strings.terms.label}
            </a>
          }
        />
      </section>
      {deployedContract && (
        <section className="flex w-full flex-col items-center gap-2">
          <a
            className="flex w-full flex-row gap-2"
            href={`${
              networkData[testnet ? "testnet" : "mainnet"][form.network].wallet
                .blockExplorerUrls[0]
            }tx/${deployedContract.transactionHash}`}
            target="_blank"
            rel="noreferrer"
          >
            Contract deployed at transaction{" "}
            {deployedContract.transactionHash.slice(0, 8)}...
            <FontAwesomeIcon icon={faCircleCheck} className="text-sky" />
          </a>
          <div className="flex w-full flex-row gap-2">
            Address: {deployedContract.contractAddress}
          </div>
        </section>
      )}
      <div className="flex w-full justify-end">{actionButton}</div>
    </form>
  )
}

export default Form
