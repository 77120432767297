import React from "react"
const Loader = ({ color }) => {
  return (
    <span className={`w-full ${color || "text-sky"}`}>
      <svg class="loader" viewBox="0 0 100 100">
        <circle
          class="spinner"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          cx="50"
          cy="50"
          r="43"
        />
      </svg>
    </span>
  )
}

export default Loader
