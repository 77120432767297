import React from "react"
import ContentWithTooltip from "./tooltip"

export const FloatingInput = ({
  placeholder,
  setValue,
  name,
  type,
  value,
  max,
  min,
  tip,
}) => {
  return (
    <ContentWithTooltip tip={tip}>
      <div class="relative w-full">
        <input
          type={type}
          id={name}
          min={min || 0}
          max={max || 100}
          value={value}
          class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border appearance-none border-gray-300 focus:outline-none focus:ring-0 focus:border-sky peer"
          placeholder=" "
          onChange={e => setValue(e.target.value)}
        />
        <label
          for={name}
          class="absolute rounded-lg text-sm text-gray-500 duration-300 ease-out transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-opacity-100 peer-focus:bg-opacity-100 px-2 peer-focus:px-2 peer-focus:text-sky peer-placeholder-shown:scale-100 peer-placeholder-shown:bg-opacity-0 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
        >
          {placeholder}
        </label>
      </div>
    </ContentWithTooltip>
  )
}

export const Toggle = ({ handleChange, value, name, label, tip }) => {
  return (
    <ContentWithTooltip tip={tip}>
      <label
        for={name}
        class="flex flex-row items-center cursor-pointer space-x-2 justify-between w-full"
      >
        <span class="text-sm font-medium text-gray-900">{label}</span>
        <div className="relative">
          <input
            type="checkbox"
            id={name}
            class="sr-only peer"
            checked={value}
            onChange={handleChange}
          />
          <div class="w-11 h-6 bg-gray-200 outline-none peer-hover:after:ring-2 peer-focus:after:ring-2 peer-hover:after:ring-sky peer-focus:after:ring-sky peer-checked:peer-hover:after:ring-palid peer-checked:peer-focus:after:ring-palid rounded-full peer after:duration-200 after:ease-in peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all transform-gpu duration-200 peer-checked:bg-sky"></div>
        </div>
      </label>
    </ContentWithTooltip>
  )
}
